<template>
    <section>
      
      <div class="columns is-marginless">
        <div class="column is-12">    
            <div class="columns is-gapless is-marginless is-centered is-mobile">
              <div class="column is-offset-1 is-6 is-mobile">
                <div v-if="companyData !== null" class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light" >
                  {{ companyData.companies_id }} - {{ companyData.companies_name }}
                </div>              
                
                <div v-if="vacancyInfoDataInfo" class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light" >
                  
                  schedule: {{ vacancyInfoDataInfo.wa_schedules_id }} - {{ vacancyInfoDataInfo.wa_schedules_name }}<br>
                  shiftId: {{ vacancyInfoDataInfo.wa_shifts_id }} - vacancyId: {{ vacancyInfoDataInfo.wa_shifts_vacancy_id }}<br>
                  name: {{ vacancyInfoDataInfo.wa_codes_name }} - code: {{ vacancyInfoDataInfo.wa_codes_code }}<br>
                  date: {{ DDMMYYYY(new Date(vacancyInfoDataInfo.wa_shifts_start_datetime)) }}
                         {{ HHii(new Date(vacancyInfoDataInfo.wa_shifts_start_datetime)) }}-{{ HHii(new Date(vacancyInfoDataInfo.wa_shifts_end_datetime)) }}
                  <Br />
                  ASSIGNED USERID: {{ vacancyInfoDataInfo.wa_vacancies_assigned_user_id }}

                </div>
                
              </div>
              <div class="column is-3 is-mobile">
                <!-- dynamic rates -->
                <div v-if="vacancyId !== null && vacancyMaxResponse && vacancyMaxResponse.length > 0" class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light" >
                  <a :href='`${WEB_ROOT}/company/${companyId}/rates/shift/${vacancyMaxResponse[0].shifts_id}`' target='_blank' class="highlight">Dynamic rates</a>
                </div>
                
                <!-- shift  -->
                <div v-if="vacancyInfoDataInfo !== null && vacancyMaxResponse && vacancyMaxResponse.length > 0" class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light" >
                  <a :href='`${SITE_ROOT}/companies/${companyId}/schedules/${vacancyInfoDataInfo.wa_schedules_id}/shifts/?year=${new Date(vacancyInfoDataInfo.wa_shifts_start_datetime).getFullYear()}&month=${new Date(vacancyInfoDataInfo.wa_shifts_start_datetime).getMonth() +1}`' target='_blank' class="highlight">View in schedule</a>
                </div>
                
              </div>
            </div>
        </div>
      </div>
      
      
      
      <div v-if="vacancyMaxResponse && vacancyMaxResponse[0] && (vacancyMaxResponse[0].status === 'assigned' || vacancyMaxResponse[0].status === 'delegated')" class="is-mobile is-gapless is-centered is-multiline m2rem">
        <h1>Vacancy {{ vacancyId }} assigned To:</h1>
        <b-table
            :data="vacancyMaxResponse"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :loading="tableVacancyIsLoading"
            :mobile-cards="true">
            
            <b-table-column field="vacancies_id" label="vacanciesId" width="40" :td-attrs="columnTdAttrs" centered numeric v-slot="props">
                {{ props.row.vacancies_id }}
            </b-table-column>

            <b-table-column field="response_statuses_id" label="response_statuses_id" width="1000" :td-attrs="columnTdAttrs" centered v-slot="props">
                {{ props.row.response_statuses_id }}
            </b-table-column>
            <b-table-column field="status" label="status" width="1000" :td-attrs="columnTdAttrs" v-slot="props" centered>
                {{ props.row.status }}
            </b-table-column>
            <b-table-column field="user_id" label="user_id" width="1000" :td-attrs="columnTdAttrs" v-slot="props" centered>
                {{ props.row.user_id }}
            </b-table-column>
            <b-table-column field="users_name" label="users_name" width="1000" :td-attrs="columnTdAttrs" v-slot="props">
                {{ props.row.users_name }}
            </b-table-column>
            <b-table-column field="users_email" label="users_email" width="1000" :td-attrs="columnTdAttrs" v-slot="props">
                {{ props.row.users_email }}
            </b-table-column>
            <b-table-column field="profiles_last_name" label="profile" width="1000" :td-attrs="columnTdAttrs" v-slot="props">
                {{ props.row.profiles_salutation }} {{ props.row.profiles_initials }} {{ props.row.profiles_title }} {{ props.row.profiles_first_name }} {{ props.row.profiles_insertion }} {{ props.row.profiles_last_name }}
            </b-table-column>

            <template #empty>
                <div class="has-text-centered">No records</div>
            </template>

        </b-table>
      </div>
      
      
      
      <div v-if="false" class="is-mobile is-gapless is-centered is-multiline m2rem">
        <h1>Vacancy Holiday Data:</h1>
        <b-switch v-model="showHolidayTable"> {{ showHolidayTable ? 'Hide' : 'Show' }} </b-switch>
        <b-table
            v-show="showHolidayTable"
            :data="vacancyHolidayData"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :loading="tablevacancyHolidayIsLoading"
            :mobile-cards="true">
            
            <b-table-column field="wa_company_holidays_company_id" label="company_id at" width="1000" :td-attrs="columnTdAttrs" v-slot="props">
                {{ props.row.wa_company_holidays_company_id }}
            </b-table-column>
            
            <b-table-column field="wa_company_holiday_codes_id" label="code id" width="40" :td-attrs="columnTdAttrs" centered numeric v-slot="props">
                {{ props.row.wa_company_holiday_codes_id }}
            </b-table-column>

            <b-table-column field="wa_company_holiday_codes_company_holiday_id" label="company holiday id" width="1000" :td-attrs="columnTdAttrs" centered v-slot="props">
                {{ props.row.wa_company_holiday_codes_company_holiday_id }}
            </b-table-column>
            
            <b-table-column field="wa_company_holiday_codes_code_id" label="holiday code id" width="1000" :td-attrs="columnTdAttrs" v-slot="props" centered>
                {{ props.row.wa_company_holiday_codes_code_id }}
            </b-table-column>
            
            <b-table-column field="wa_company_holidays_name" label="name" width="1000" :td-attrs="columnTdAttrs" v-slot="props">
                {{ props.row.wa_company_holidays_name }}
            </b-table-column>
            
            <b-table-column field="wa_company_holiday_codes_hourly_rate" label="hourly rate" width="1000" :td-attrs="columnTdAttrs" v-slot="props" centered>
                {{ props.row.wa_company_holiday_codes_hourly_rate }}
            </b-table-column>
            
            <b-table-column field="wa_company_holiday_codes_created_at" label="created at" width="1000" :td-attrs="columnTdAttrs" v-slot="props">
                {{ DDMMYYYY_HHii(new Date(props.row.wa_company_holiday_codes_created_at)) }}
            </b-table-column>
            <b-table-column field="wa_company_holidays_start_datetime" label="start at" width="1000" :td-attrs="columnTdAttrs" v-slot="props">
                {{ DDMMYYYY_HHii(new Date(props.row.wa_company_holidays_start_datetime)) }}
            </b-table-column>
            <b-table-column field="wa_company_holidays_end_datetime" label="end at" width="1000" :td-attrs="columnTdAttrs" v-slot="props">
                {{ DDMMYYYY_HHii(new Date( props.row.wa_company_holidays_end_datetime)) }}
            </b-table-column>
            <b-table-column field="wa_company_holidays_year" label="year" width="1000" :td-attrs="columnTdAttrs" v-slot="props">
                {{ props.row.wa_company_holidays_year }}
            </b-table-column>
            <b-table-column field="wa_company_holidays_type" label="type" width="1000" :td-attrs="columnTdAttrs" v-slot="props">
                {{ props.row.wa_company_holidays_type }}
            </b-table-column>
            
            
            <b-table-column field="profiles_last_name" label="profile" width="1000" :td-attrs="columnTdAttrs" v-slot="props">
                {{ props.row.profiles_salutation }} {{ props.row.profiles_initials }} {{ props.row.profiles_title }} {{ props.row.profiles_first_name }} {{ props.row.profiles_insertion }} {{ props.row.profiles_last_name }}
            </b-table-column>

            <template #empty>
                <div class="has-text-centered">No records</div>
            </template>

        </b-table>
      </div>
        
      
      <div class="is-mobile is-gapless is-centered is-multiline m2rem">
        <h1>Vacancy {{ vacancyId }} Info:</h1>
        <b-switch v-model="showVacancyInfoTable"> {{ showVacancyInfoTable ? 'Hide' : 'Show' }} </b-switch>
        <b-table
            v-show="showVacancyInfoTable"
            :data="vacancyInfo"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :loading="tableVacancyInfoIsLoading"
            :mobile-cards="true">
            
            <b-table-column field="keyName" label="keyName" width="40" :td-attrs="columnTdAttrs" numeric v-slot="props" sortable searchable>
                {{ props.row.keyName }}
            </b-table-column>

            <b-table-column field="keyValue" label="Value" width="1000" :td-attrs="columnTdAttrs" v-slot="props" sortable searchable>
                {{ props.row.keyValue }}
            </b-table-column>

            <template #empty>
                <div class="has-text-centered">No records</div>
            </template>

        </b-table>
      </div>
        
      
      <div class="columns is-marginless">
        <div class="column is-12 is-gapless is-centered is-multiline">
          <b-switch v-model="showAdvanced"> showAdvanced </b-switch>
          <h1>users:</h1>          
          <b-button v-for="(userId) in vacancyResponsesUserIdList" v-bind:key="`${userId}_index`" 
              class="button m1rem" 
              :class="vacancyResponsesFilterUserIdList.size === 0 || vacancyResponsesFilterUserIdList.has(userId) ? 'is-primary' : ''"
              @click="toggleFilterUser(userId)">
            <strong>{{ userId }}</strong>
          </b-button>
        </div>
      </div>
      
      
      <div class="is-mobile is-gapless is-centered is-multiline m2rem">
        <h1>Vacancy {{ vacancyId }} Responses:</h1>
        <b-table
            :data="vacancyResponsesFiltered"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :loading="tableVacancyResponsesIsLoading"
            :mobile-cards="true">
            
            <b-table-column field="wa_responses_id" label="responses Id" width="40" :td-attrs="columnTdAttrs" v-slot="props" centered numeric searchable>
                {{ props.row.wa_responses_id }}
            </b-table-column>
            
            <b-table-column field="wa_responses_is_latest" label="is Latest 1" width="40" :td-attrs="columnTdAttrs" v-slot="props" centered numeric searchable>
                <div class="mybutton" @click="toggleResponseId(props.row)"> {{ props.row.wa_responses_is_latest }} </div>
            </b-table-column>
            
            <b-table-column field="wa_response_statuses_id" label="response Statuses Id" width="40" :td-attrs="columnTdAttrs" v-slot="props" centered numeric  searchable>
                {{ props.row.wa_response_statuses_id }}
                <b-button v-if="showAdvanced" class="is-small button is-primary" @click="setToLatestId(props.row.wa_response_statuses_id)">1st</b-button>
            </b-table-column>
            
            <b-table-column field="wa_response_statuses_is_latest" label="is Latest 2" width="40" :td-attrs="columnTdAttrs" v-slot="props" centered numeric sortable searchable>
                <div class="mybutton" @click="toggleResponseStatusId(props.row)">{{ props.row.wa_response_statuses_is_latest }}</div>
            </b-table-column>

            <b-table-column field="wa_response_statuses_created_at" label="created at" width="40" :td-attrs="columnTdAttrs" v-slot="props" centered date>
                {{ DDMMYYYY_HHii(new Date(props.row.wa_response_statuses_created_at)) }}
            </b-table-column>
            
            <b-table-column field="wa_responses_user_id" label="user_id" width="40" :td-attrs="columnTdAttrs" v-slot="props" centered numeric sortable searchable>
                <div :class="vacancyInfoDataInfo.wa_vacancies_assigned_user_id === props.row.wa_responses_user_id ? 'isAssignedUserId' : ''">
                  <a :href='`${WEB_ROOT}/company/${companyId}/user/${props.row.wa_responses_user_id}`' target='_blank' class="highlight">
                    {{ props.row.wa_responses_user_id }}
                  </a>
                </div>
                <b-button v-if="showAdvanced" class="is-small button is-primary" @click="setVacancyAssignedUserId(props.row.wa_responses_user_id)">vacancy Assigned User-ID</b-button>
            </b-table-column>
            
            <b-table-column field="wa_statuses_key" label="statuses" width="60" :td-attrs="columnTdAttrs" v-slot="props" centered sortable searchable>
                {{ props.row.wa_statuses_key }}
            </b-table-column>
            
            <!-- <b-table-column field="wa_google_or_responses_id" label="google or rId" width="40" :td-attrs="columnTdAttrs" v-slot="props" centered numeric sortable searchable>
                {{ props.row.wa_google_or_responses_id }}
            </b-table-column> -->
<!--                         
            <b-table-column field="users_name" label="name" width="100" :td-attrs="columnTdAttrs" v-slot="props" sortable searchable>
                {{ props.row.users_name }}
            </b-table-column> -->
            
            <b-table-column field="last_name" label="profile" width="100" :td-attrs="columnTdAttrs" v-slot="props" sortable searchable>
                {{ props.row.salutation }} {{ props.row.initials }} {{ props.row.title }} {{ props.row.first_name }} {{ props.row.insertion }} {{ props.row.last_name }}
            </b-table-column>
            
            <b-table-column field="users_email" label="email" width="60" :td-attrs="columnTdAttrs" v-slot="props" sortable searchable>
                {{ props.row.users_email }}
            </b-table-column>
            
            
            
            <b-table-column field="wa_response_statuses_user_id" label="assigned by" width="100" :td-attrs="columnTdAttrs" v-slot="props" sortable searchable centered>
                <div v-if="props.row.wa_response_statuses_user_id === null">
                  system
                </div>
                <div v-if="props.row.wa_response_statuses_user_id !== null && props.row.wa_response_statuses_user_id !== props.row.wa_responses_user_id">
                  <a :href='`${WEB_ROOT}/company/${companyId}/user/${props.row.wa_response_statuses_user_id}`' target='_blank' class="highlight">{{ props.row.wa_response_statuses_user_id }}</a><br />
                  {{ props.row.assignedBy_email}}
                </div>
            </b-table-column>
            
            <b-table-column field="wa_phases_name" label="phase" width="100" :td-attrs="columnTdAttrs" v-slot="props"  centered>
                {{ props.row.wa_phases_name ? props.row.wa_phases_name : '' }}
                {{ props.row.wa_phases_order_number }}
            </b-table-column>
            
            <!-- <b-table-column field="wa_phases_order_number" label="phases order number" width="100" :td-attrs="columnTdAttrs" v-slot="props" centered sortable searchable>                
            </b-table-column> -->
            
            <b-table-column v-if="showAdvanced"  label="action" width="100" :td-attrs="columnTdAttrs" v-slot="props" centered >
                <b-button  class="button is-primary" @click="removeResponseStatus(props.row)">
                  <strong>remove - {{ props.row.wa_response_statuses_id }}</strong>
                </b-button>
            </b-table-column>

            <template #empty>
                <div class="has-text-centered">No records</div>
            </template>

        </b-table>
      </div>
        
      
      
      <b-notification :closable="false">
        <b-loading :is-full-page="true" :active.sync="pageLoading"></b-loading>
      </b-notification>
    </section>
</template>

<script>

import axios_api from '@/plugins/axios_api';
// import { waitTime }         from '@/helpers/functions';
// import { mapState } from 'vuex';
import { DDMMYYYY_HHii, DDMMYYYY, HHii } from '@/helpers/dates.js';

/* eslint-disable */
import { WEB_ROOT, API_ROOT } from '@/config/app.js';

    export default {
      data() {
        return {
          companyId           : null,
          vacancyId           : null,
          companyData         : null,
          SITE_ROOT     : process.env.VUE_APP_SITE_MAIN,
          
          showHolidayTable      : false,
          showVacancyInfoTable  : false,
          
          showAdvanced : false,
          
          vacancyMaxResponse         : [],
          tableVacancyIsLoading       : false,
          vacancyInfo                 : [],
          vacancyInfoDataInfo         : null,
          tableVacancyInfoIsLoading   : false,
          vacancyResponses            : [],
          
          vacancyResponsesFiltered    : [],
          vacancyResponsesUserIdList        : [],
          vacancyResponsesFilterUserIdList  : new Set([]),
          
          
          tableVacancyResponsesIsLoading   : false,
          
          vacancyHolidayData: [],
          tablevacancyHolidayIsLoading: false,
          
          WEB_ROOT: WEB_ROOT,
          API_ROOT: API_ROOT,
          
          pageLoading         : false,
          pageLoadingTimeout  : null,
        }
      },
      async mounted() {
        this.companyId = this.$route.params.companyId;
        this.vacancyId = this.$route.params.vacancyId;
        this.ShowPageLoading(15000)
        this.getCompanyData()
      },
      methods: {
        DDMMYYYY_HHii, DDMMYYYY, HHii,
        async getCompanyData() {
          let response = await axios_api.get(`${API_ROOT}/schedule/company/${this.companyId}/vacancy/${this.vacancyId}`, {});
          if (response.data.result !== 1) {
            this.$buefy.dialog.alert({ title: 'Error', message: `unable to get company vacancy ${this.vacancyId} data`, type: 'is-danger' })
            this.HidePageLoading()
            return;
          }
          // console.info(`response:`, response)
          this.companyData = response.data.payload.companyData
          this.vacancyMaxResponse = response.data.payload.vacancyMaxResponse
          // console.info(`this.vacancyMaxResponse:`, this.vacancyMaxResponse)
          this.vacancyHolidayData = response.data.payload.vacancyHolidayData
          
          const vacancyInfo = response.data.payload.vacancyInfo[0]
          this.vacancyInfoDataInfo = vacancyInfo
          // console.info(`this.vacancyInfoDataInfo:`, vacancyInfo)
          
          let vacancyDataList = []
          for(let vacancyKey in vacancyInfo) {
            vacancyDataList.push({
              keyName: vacancyKey,
              keyValue: vacancyInfo[vacancyKey]
            })
          }
          this.vacancyInfo = vacancyDataList;
          // console.info(`this.vacancyInfo:`, this.vacancyInfo)
          
          this.vacancyResponses = response.data.payload.vacancyResponses
          // console.info(`this.vacancyResponses:`, this.vacancyResponses)
          
          this.filtervacancyResponses()
          this.HidePageLoading()
        },
        filtervacancyResponses(){
          let responsesFiltered = []
          let users = new Set([])
          for(let response of this.vacancyResponses) {
            // console.info(`response:`, response)
            users.add(response.users_id)
            if (this.vacancyResponsesFilterUserIdList.size === 0) {
              responsesFiltered.push(response)
              continue
            }
            if (this.vacancyResponsesFilterUserIdList.has(response.users_id)) {
              responsesFiltered.push(response)
              continue
            }
          }
          // console.info(`responsesFiltered:`, responsesFiltered)
          this.vacancyResponsesFiltered = responsesFiltered
          // console.info(`users:`, users)
          this.vacancyResponsesUserIdList = [...users]
        },
        toggleFilterUser(userId){
          if (this.vacancyResponsesFilterUserIdList.has(userId)) {
            this.vacancyResponsesFilterUserIdList.delete(userId)
          } else {
            this.vacancyResponsesFilterUserIdList.add(userId)
          }
          this.filtervacancyResponses()
        },
        
        /* eslint-disable-next-line */        
        dateThAttrs(column) {
          return null
        },
                
        /* eslint-disable-next-line */
        columnTdAttrs(row, column) {
          return null
        },
        
        async ShowPageLoading(maxDuration = 30000){
          this.pageLoading = true;
          
          if (this.pageLoadingTimeout !== null) {
            clearTimeout(this.pageLoadingTimeout);
          }
          
          this.pageLoadingTimeout = setTimeout(() => {
            this.HidePageLoading();
          }, maxDuration);
        },
        
        async HidePageLoading() {
          this.pageLoading = false;
          if (this.pageLoadingTimeout !== null) {
            clearTimeout(this.pageLoadingTimeout);
          }
        },
        
        async toggleResponseId(response) {          
          const responseId = response.wa_responses_id
          let newState = 0
          if (parseInt(response.wa_responses_is_latest) !== 1) {
            newState = 1
          }
          
          this.$buefy.dialog.confirm({ 
            title: 'Error', 
            message: `Set ResponseId: ${responseId} to ${newState}`,
            type: 'is-danger',
            onConfirm: async () => {
                this.ShowPageLoading(5000)
                let rtn = await axios_api.post(`${API_ROOT}/schedule/company/${this.companyId}/vacancy/${this.vacancyId}/responseId/${responseId}/${newState}`, {});
                this.HidePageLoading()
                // location.reload()
                this.getCompanyData()
              }           
          })          
        },
        
        async setToLatestId(responseStatusId) {          
          
          this.$buefy.dialog.confirm({ 
            title: 'Error', 
            message: `Set to MAX() ResponseStatusId: ${responseStatusId}`,
            type: 'is-danger',
            onConfirm: async () => {
                this.ShowPageLoading(5000)
                let rtn = await axios_api.post(`${API_ROOT}/schedule/company/${this.companyId}/vacancy/${this.vacancyId}/setToMaxId/responseStatusId/${responseStatusId}`, {});
                this.HidePageLoading()
                // location.reload()
                this.getCompanyData()
              }           
          })          
        },
        
        async setVacancyAssignedUserId(userId) {          
          
          this.$buefy.dialog.confirm({ 
            title: 'Error', 
            message: `Set to vacancyAssignedUserId To userId: ${userId}`,
            type: 'is-danger',
            onConfirm: async () => {
                this.ShowPageLoading(5000)
                let rtn = await axios_api.post(`${API_ROOT}/schedule/company/${this.companyId}/vacancy/${this.vacancyId}/setAssignedToUserId/${userId}`, {});
                this.HidePageLoading()
                // location.reload()
                this.getCompanyData()
              }           
          })          
        },
        
        async toggleResponseStatusId(response) {          
          const responseStatusId = response.wa_response_statuses_id
          let newState = 0
          if (parseInt(response.wa_response_statuses_is_latest) !== 1) {
            newState = 1
          }
          
          this.$buefy.dialog.confirm({ 
            title: 'Error', 
            message: `Set ResponseStatusId: ${responseStatusId} to ${newState}`,
            type: 'is-danger',
            onConfirm: async () => {
                this.ShowPageLoading(5000)
                let rtn = await axios_api.post(`${API_ROOT}/schedule/company/${this.companyId}/vacancy/${this.vacancyId}/responseStatusId/${responseStatusId}/${newState}`, {});
                this.HidePageLoading()
                this.getCompanyData()
                // location.reload()
              }           
          })          
        },
        
        async removeResponseStatus(response) {
          this.ShowPageLoading(5000)
          const responseStatusId = response.wa_response_statuses_id
          let rtn = await axios_api.post(`${API_ROOT}/schedule/company/${this.companyId}/vacancy/${this.vacancyId}/removeResponse/${responseStatusId}/0`, {});
          this.HidePageLoading()
          if (rtn.data.result !== 1) {
            this.$buefy.dialog.alert({ title: 'Error', message: `unable to remove vacancy ${this.vacancyId} - ResponseStatus: ${responseStatusId} data`, type: 'is-danger' })  
            return;
          }
          
          
          let sumary = ``
          for(let key in rtn.data.payload.responseStatusInfo[0]) {
            sumary += `${key}: ${rtn.data.payload.responseStatusInfo[0][key]}<br>`
          }
          sumary += `<br>`
          sumary += `# Querries:<br>`
          sumary += rtn.data.payload.rtnQuerries.join(`<br>`)
          sumary += `ARE YOU SURE ?`
          console.info(`sumary:`, sumary)
          
          console.info(`sumary:`, sumary)
          console.info(`START TRANSACTION;`)
          console.info(rtn.data.payload.rtnQuerries.join(`\n`))
          console.info(`# COMMIT;`)
          
          
          this.$buefy.dialog.confirm({
              message: sumary,
              size:"is-large",
              onConfirm: async () => {
                this.ShowPageLoading(5000)
                const responseStatusId = response.wa_response_statuses_id
                let rtn = await axios_api.post(`${API_ROOT}/schedule/company/${this.companyId}/vacancy/${this.vacancyId}/removeResponse/${responseStatusId}/1`, {});
                this.HidePageLoading()
                if (rtn.data.result !== 1) {
                  this.$buefy.dialog.alert({ title: 'Error', message: `unable to remove vacancy ${this.vacancyId} - ResponseStatus: ${responseStatusId} data`, type: 'is-danger' })  
                  return;
                }
                this.getCompanyData()
                // location.reload()
              }
        })
            
          console.info(`rtn:`, rtn)
        },
      }
    }
</script>

<style>
  .highlight{
    color: #0000cc;
    text-decoration: underline;
  }
  
  .mybutton{
    padding: 5px;
  }
  .mybutton:hover{
    background-color: #ff7e82;
    color: #FFF;
    cursor: pointer;
  }
  
  .isAssignedUserId{
    background-color: lightgreen;
  }
</style>