<template>
    <section :key="anyOneCanAssign && anyOneCanCancel" :class="getBackgroundClass()" class="defaultBoxWrap">

      <div class="columns is-marginless">
        <div class="column is-12">
            <div class="columns is-gapless is-marginless is-centered is-mobile">
              <div class="column is-narrow is-mobile">
                <div v-if="companyData !== null" class="has-text-grey is-size-3 is-size-4-mobile has-text-weight-light wa-underlined" >
                  {{ companyData.companies_id }} - {{ companyData.companies_name }} -> Schedule Permissions
                  &nbsp;
                  <a :href='`${SITE_ROOT}/companies/${companyData.companies_id}/roles`' target='_blank'>WA</a>
                </div>
              </div>
            </div>
        </div>
      </div>

      <div class="defaultModesWrap">
        <div v-for="(defaultMode) in defaultModes" v-bind:key="`default_mode_${defaultMode}`"
          class="defaultMode"
          :class="selectedDefaultMode === defaultMode ? 'selected' : ''"
          @click="setDefaultMode(defaultMode)">
          {{ defaultMode }}
        </div>
      </div>

      <div class="columns is-marginless is-multiline">
        <div class="column is-3 is-offset-1">
          <!-- <h3>Period:</h3> -->
          <div class="columns is-multiline">
            <div class="column is-12" v-for="(period) in periodData" v-bind:key="`period_${period.company_periods_id}`">
              <b-button @click="selectPeriod(period)" :type="selectedPeriod && period.company_periods_id === selectedPeriod.company_periods_id ? 'is-success' : ''">
                {{ period.company_periods_id }}-{{ period.company_periods_name }}:
                {{ DDMMYYYY(new Date(period.company_periods_start_date)) }} t/m  {{ DDMMYYYY(new Date(period.company_periods_end_date)) }}
              </b-button>
            </div>
          </div>
        </div>

        <div class="column is-4">
          <!-- <h3>Schedules:</h3> -->
          
          <div class="columns is-multiline">
            <div v-show="selectedPeriod.company_periods_id === schedule.company_period_id" class="column is-12 mypadding" v-for="(schedule) in scheduleData" v-bind:key="`schedule_${schedule.id}`">
              <b-button @click="showSchedule(schedule)" :type="schedule.btnActive === true ? 'is-success' : ''">
                <div :class="schedule.is_active !== 1 ? 'ScheduleInactive' : '' ">{{ schedule.id }} - {{ schedule.name }}</div>
              </b-button>
              &nbsp;
              <a :href='`${SITE_ROOT}/companies/${companyData.companies_id}/schedules/${schedule.id}/edit?tab=1`' target='_blank'>wa</a>
            </div>
          </div>
          <b-field v-if="toScheduleList.length > 0" label="Select datetime" style="max-width: 50%;">
            <b-datetimepicker
                v-model="scheduledActiveDatetime"
                rounded
                placeholder="Click to select..."
                icon="calendar-today"
                :icon-right="scheduledActiveDatetime ? 'close-circle' : ''"
                icon-right-clickable
                @icon-right-click="clearDateTime"
                :locale="locale"
                :first-day-of-week="firstDayOfWeek"
                :datepicker="{ showWeekNumber }"
                :timepicker="{ enableSeconds, hourFormat }"
                horizontal-time-picker>
            </b-datetimepicker>
          </b-field>  
          <div v-if="toScheduleList.length > 0" class="columns is-marginless is-multiline">        
            <div v-for="(toSchedule, index) in toScheduleList" v-bind:key="`toSchedule_${index}`" class="column is-12">
              {{ toSchedule.scheduleId }} - {{ toSchedule.schedule_name }} - Phase: {{ toSchedule.phaseName }}
              <span @click="removeToScheduleItem(toSchedule)" class="removeToScheduleItem">X</span>
            </div>
            <b-button @click="StartSchedule()" :type="'is-danger'">Start Schedule</b-button>              
          </div>
          
        </div>
        
        

        <div v-if="schedulePhases.length > 0" class="column is-3 is-centered">
          <!-- <h3>phases:</h3> -->
          <div class="columns is-multiline">
            <div :key="`phases_${lastUpdated}`">
              <div  class="column is-12 mypadding" v-for="(phase) in schedulePhases" v-bind:key="`schedule_phase_${phase.phaseId}`">
                <b-button @click="showPhase(phase)" :type="phase.btnActive === true ? 'is-success' : ''">
                  <div>{{ phase.name }} ({{ phase.cancelPercent }}%)</div>
                  <div :class="phase.style">{{ phase.hasInitiateAt ? `${phase.initiateAtStr} - (${phase.daysTillInit}dagen)` :  phase.initiateAtStr}}</div>

                  <!-- <b-notification :closable="false"> -->
                    <b-loading v-if="phase.btnActive === true" :is-full-page="false" :active.sync="pageLoading"></b-loading>
                  <!-- </b-notification>       -->
                </b-button>
                &nbsp;
                <span v-if="phase.btnActive === true" @click="savePhase(phase)" class="savePhase">save</span>
                <span v-if="phase.btnActive === true" @click="setPhase(phase)" class="setPhase">setPhase</span>
                <span v-if="phase.btnActive === true" @click="addToScheduleList(phase)" class="addToScheduleList">schedule</span>
              </div>
            </div>


          </div>
        </div>

      </div>



      <div v-if="selectedSchedule" class="is-mobile is-gapless is-centered is-multiline m2rem">
        <!-- <h1>Roles</h1> -->
         <!-- <h2>
          WA -
          <a :href='`${SITE_ROOT}/companies/${companyData.companies_id}/roles`' target='_blank'>Roles</a>
          -
          <a :href='`${SITE_ROOT}/companies/${companyData.companies_id}/schedules/${selectedSchedule.id}/shifts`' target='_blank'>WA Schedule</a>
         </h2> -->
         <b-switch size="is-small" v-model="showAllRoles" @input="showPhase(selectedPhase)"> {{ showAllRoles ? 'All' : 'Schedule only' }} </b-switch>
         <b-switch size="is-small" v-model="showDifferences">showDifferences</b-switch>
         <!--
         <div v-show="showRolesTable" class="columns is-gapless is-multiline m2rem">
          <div class="column is-1" v-for="(role) in companyRolesButtons" v-bind:key="`${role.id}_index`">
            <b-button @click="toggleShowRoleStatus(role)" :type="role.show === true ? 'is-success' : ''">
              {{  role.name }}
            </b-button>
          </div>
        </div> -->
        <b-table
            v-show="permissionData.length > 0"
            :data="permissionData"
            :bordered="true"
            :striped="true"
            :narrowed="true"
            :mobile-cards="true">

            <template v-for="column in permissionColumnData">
              <b-table-column :key="column.field" :field="column.field" :label="column.label" centered v-slot="props">
                  <template v-if="column.field === `permission`">
                      <div class='cellClickAction'>{{props.row.permission}}</div>
                  </template>
                  <template v-else>
                      <!-- <b-button :type="'is-success'"> -->
                        <div class='cellClickAction'
                          :class="permissionChangedClass(props.row, column.field)"
                          @click="togglePermission(props.row, column.field)">{{ props.row[column.field] }}
                        </div>
                      <!-- </b-button> -->
                  </template>
              </b-table-column>
            </template>



            <!-- <b-table-column label="Actions">
              <button class="button is-small is-light">
                <b-icon icon="edit" size="is-small"></b-icon>
              </button>
            </b-table-column> -->

            <template #empty>
                <div class="has-text-centered">No records</div>
            </template>

        </b-table>
      </div>




    </section>
</template>

<script>

import axios_api from '@/plugins/axios_api';
// import { waitTime }         from '@/helpers/functions';
// import { mapState } from 'vuex';
import { DDMMYYYY, DDMMYYYY_HHii } from '@/helpers/dates.js';

/* eslint-disable */
import { WEB_ROOT, API_ROOT } from '@/config/app.js';

    export default {
      data() {
        return {
          companyData         : [],
          tableIsLoading      : false,
          lastUpdated         : new Date().getTime(),

          WEB_ROOT            : WEB_ROOT,
          API_ROOT            :   API_ROOT,
          SITE_ROOT           : process.env.VUE_APP_SITE_MAIN,
          
          scheduledActiveDatetime: new Date(),
          showWeekNumber      : false,
          enableSeconds       : true,
          hourFormat          : '24', // Browser locale
          locale              : 'nl-NL', // Browser locale
          firstDayOfWeek      : 1, // 0 - Sunday
          toScheduleList      : [],
          selected            : null,


          companyData         : [],
          scheduleData        : [],
          periodData          : [],
          selectedPeriod      : null,
          schedulePhases      : [],
          selectedSchedule    : null,
          selectedPhase       : null,
          showAllRoles        : false,
          showDifferences     : false,

          rolePermissions       : [],
          permissionData        : [],
          permissionColumnData  : [],

          pageLoading         : false,
          pageLoadingTimeout  : null,

          lastSchedulePermissions: null,

          anyOneCanCancel: false,
          anyOneCanAssign: false,
          cancelUnlimited: false,
          warnings : [],

          defaultModes: ["live", "fase 3b", "fase 3a", "fase 2b", "fase 2a", "fase 1", "fase 0"],
          selectedDefaultMode: null,
          modeOptions: {
            "fase 0": {
              "view_schedule": false,
              "view_own_assigned_shift": false,
              "view_own_responses": false,
              "update_min_max (role)": false,
              "update_min_max (codes)": false,
              "view_min_max": false,
              "available_own_response": false,
              "cancel_available_own_response": false,
              "cancel_unlimited_responses": false,
              "create_block_day": false,
              "delete_block_day": false,
              "view_blocks (role)": false,
              "assign_own_response": false,
              "cancel_assigned_own_response": false,
              "add_spectator_shift": false,
              "trade_create": false,
              "offering_shift": false,
              "exchange_response": false,
              "respond_vacancy_members_only": true,
              "create_vacancy_members_only": true,
              "delegate_quotum": true,
              "take_quotum": true,
              "view_quotum": true
            },
            "fase 1": {
              "view_schedule": true,
              "view_own_assigned_shift": true,
              "view_own_responses": true,
              "update_min_max (role)": true,
              "update_min_max (codes)": true,
              "view_min_max": true,
              "available_own_response": true,
              "cancel_available_own_response": true,
              "cancel_unlimited_responses": true,
              "create_block_day": true,
              "delete_block_day": true,
              "view_blocks (role)": true,
              "assign_own_response": false,
              "cancel_assigned_own_response": false,
              "add_spectator_shift": false,
              "trade_create": false,
              "offering_shift": false,
              "exchange_response": false,
              "respond_vacancy_members_only": true,
              "create_vacancy_members_only": true,
              "delegate_quotum": false,
              "take_quotum": false,
              "view_quotum": true
            },
            "fase 2a": {
              "view_schedule": true,
              "view_own_assigned_shift": true,
              "view_own_responses": true,
              "update_min_max (role)": false,
              "update_min_max (codes)": false,
              "view_min_max": true,
              "available_own_response": false,
              "cancel_available_own_response": false,
              "cancel_unlimited_responses": false,
              "create_block_day": false,
              "delete_block_day": false,
              "view_blocks (role)": true,
              "assign_own_response": true,
              "cancel_assigned_own_response": true,
              "add_spectator_shift": false,
              "trade_create": false,
              "offering_shift": false,
              "exchange_response": false,
              "respond_vacancy_members_only": true,
              "create_vacancy_members_only": true,
              "delegate_quotum": false,
              "take_quotum": false,
              "view_quotum": false
            },
            "fase 2b": {
              "view_schedule": true,
              "view_own_assigned_shift": true,
              "view_own_responses": true,
              "update_min_max (role)": false,
              "update_min_max (codes)": false,
              "view_min_max": true,
              "available_own_response": false,
              "cancel_available_own_response": false,
              "cancel_unlimited_responses": false,
              "create_block_day": false,
              "delete_block_day": false,
              "view_blocks (role)": true,
              "assign_own_response": true,
              "cancel_assigned_own_response": true,
              "add_spectator_shift": false,
              "trade_create": false,
              "offering_shift": false,
              "exchange_response": false,
              "respond_vacancy_members_only": true,
              "create_vacancy_members_only": true,
              "delegate_quotum": false,
              "take_quotum": false,
              "view_quotum": true
            },
            "fase 3a": {
              "view_schedule": true,
              "view_own_assigned_shift": true,
              "view_own_responses": true,
              "update_min_max (role)": false,
              "update_min_max (codes)": false,
              "view_min_max": true,
              "available_own_response": false,
              "cancel_available_own_response": false,
              "cancel_unlimited_responses": false,
              "create_block_day": false,
              "delete_block_day": false,
              "view_blocks (role)": true,
              "assign_own_response": true,
              "cancel_assigned_own_response": false,
              "add_spectator_shift": false,
              "trade_create": false,
              "offering_shift": false,
              "exchange_response": false,
              "respond_vacancy_members_only": true,
              "create_vacancy_members_only": true,
              "delegate_quotum": false,
              "take_quotum": false,
              "view_quotum": true
            },
            "fase 3b": {
              "view_schedule": true,
              "view_own_assigned_shift": true,
              "view_own_responses": true,
              "update_min_max (role)": false,
              "update_min_max (codes)": false,
              "view_min_max": true,
              "available_own_response": false,
              "cancel_available_own_response": false,
              "cancel_unlimited_responses": false,
              "create_block_day": false,
              "delete_block_day": false,
              "view_blocks (role)": true,
              "assign_own_response": false,
              "cancel_assigned_own_response": false,
              "add_spectator_shift": false,
              "trade_create": false,
              "offering_shift": false,
              "exchange_response": false,
              "respond_vacancy_members_only": true,
              "create_vacancy_members_only": true,
              "delegate_quotum": false,
              "take_quotum": false,
              "view_quotum": true
            },
            "live": {
              "view_schedule": true,
              "view_own_assigned_shift": true,
              "view_own_responses": true,
              "update_min_max (role)": false,
              "update_min_max (codes)": false,
              "view_min_max": true,
              "available_own_response": false,
              "cancel_available_own_response": false,
              "cancel_unlimited_responses": false,
              "create_block_day": false,
              "delete_block_day": false,
              "view_blocks (role)": true,
              "assign_own_response": true,
              "cancel_assigned_own_response": false,
              "add_spectator_shift": false,
              "trade_create": true,
              "offering_shift": true,
              "exchange_response": true,
              "respond_vacancy_members_only": true,
              "create_vacancy_members_only": true,
              "delegate_quotum": false,
              "take_quotum": false,
              "view_quotum": false
            },
          }
        }
      },
      async mounted() {
        this.companyId = this.$route.params.companyId;
        this.ShowPageLoading(15000)
        await this.getSchedulePermissionData()
        // await this.getRolesData();
      },
      methods: {
        DDMMYYYY, DDMMYYYY_HHii,
        async getSchedulePermissionData() {
          let response = await axios_api.get(`${API_ROOT}/schedule/company/${this.companyId}/ScheduleData`, {});
          if (response.data.result !== 1) {
            this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get company data', type: 'is-danger' })
            this.HidePageLoading()
            return;
          }
          // console.info(`response:`, response)
          this.companyData = response.data.payload.companyData
          this.scheduleData = response.data.payload.scheduleData
          this.periodData = response.data.payload.periods

          if (this.selectedPeriod === null) {
            // console.info(`auto slect ?`)
            this.selectedPeriod = this.periodData[0]
          }

          if (!this.selectedSchedule) {
            // console.info(`show schedule:`, this.scheduleData[0])
            let curSchedules = this.scheduleData.filter(s => s.company_period_id === this.selectedPeriod.company_periods_id)
            if (curSchedules.length > 0 ) {
              this.showSchedule(curSchedules[0])
            }
          }

          // console.info(`this.companyData:`, this.companyData)
          this.HidePageLoading()
        },

        /* eslint-disable-next-line */
        dateThAttrs(column) {
          return null
        },

        /* eslint-disable-next-line */
        columnTdAttrs(row, column) {
          return null
        },

        async ShowPageLoading(maxDuration = 30000){
          this.pageLoading = true;

          if (this.pageLoadingTimeout !== null) {
            clearTimeout(this.pageLoadingTimeout);
          }

          this.pageLoadingTimeout = setTimeout(() => {
            this.HidePageLoading();
          }, maxDuration);
        },

        async HidePageLoading() {
          this.pageLoading = false;
          if (this.pageLoadingTimeout !== null) {
            clearTimeout(this.pageLoadingTimeout);
          }
        },

        permissionChangedClass(props, field){
          if (!this.showDifferences) return
          if (!this.lastSchedulePermissions) return

          if (this.lastSchedulePermissions.companyId !== this.companyId) return
          if (this.lastSchedulePermissions.periodId !== this.selectedPeriod.company_periods_id) return
          if (this.lastSchedulePermissions.scheduleId !== this.selectedSchedule.id) return
          if (this.lastSchedulePermissions.phaseId === this.selectedPhase.phaseId) return
          const curRolePermissions = this.rolePermissions.find(p => p.role_name === field)
          if (!curRolePermissions) return

          const lastRolePermissions = this.lastSchedulePermissions.rolePermissions.find(p => p.role_name === field)
          if (!lastRolePermissions) return

          let curHasFieldPermission = curRolePermissions.schedulePhaseAccess && curRolePermissions.curPermissions[props.permission]
          let lastHasFieldPermission = lastRolePermissions.schedulePhaseAccess && lastRolePermissions.curPermissions[props.permission]
          if (curHasFieldPermission === lastHasFieldPermission) return

          return 'tableCellHighlight'
        },

        async showPhase(newPhase){
          if (!this.selectedSchedule) return



          // console.info(`showPhase:`, newPhase)

          let selectedPhase = null
          for(let phase of this.schedulePhases) {
            if (phase.phaseId === newPhase.phaseId) {
              phase.btnActive = true
              selectedPhase = phase
            } else {
              phase.btnActive = false
            }
          }

          // console.info(`updating lastSchedulePermissions...`, this.lastSchedulePermissions)
          // console.info(`updating lastSchedulePermissions...`, this.selectedPhase)
          // console.info(`----`)
          if (!this.lastSchedulePermissions || this.lastSchedulePermissions.newPhaseId !== selectedPhase.phaseId) {
            this.lastSchedulePermissions = {
              companyId: this.companyId,
              periodId: this.selectedPeriod.company_periods_id,
              scheduleId: this.selectedSchedule.id,
              phaseId: this.selectedPhase && this.selectedPhase.phaseId ? this.selectedPhase.phaseId : newPhase.phaseId,
              rolePermissions: this.rolePermissions,
              newPhaseId: selectedPhase.phaseId
            }
          }

          // console.info(`selectedPhase:`, selectedPhase)
          this.selectedPhase = selectedPhase
          this.lastUpdated = new Date().getTime()
          // this.permissionData = []
          // this.rolePermissions = []
          // console.info(`this.lastSchedulePermissions:`, this.lastSchedulePermissions)

          //1. get roles, permissions, default template permissions
          this.ShowPageLoading(500)
          let response = await axios_api.get(`${API_ROOT}/schedule/company/${this.companyId}/schedulePermissions/${this.selectedSchedule.id}/phaseOrderNr/${selectedPhase.phaseId}`, {});
          if (response.data.result !== 1) {
            this.$buefy.dialog.alert({ title: 'Error', message: 'unable to get phase data', type: 'is-danger' })
            this.HidePageLoading()
            return;
          }
          // console.info(`response:`, response)
          this.HidePageLoading()
          
          
          if (response.data.payload.rolePermissions.length <=0) return
          
          response.data.payload.rolePermissions = this.updateDefaultPermissions(response.data.payload.rolePermissions)
          
          this.loadPermissionsTable(response)
        },
        updateDefaultPermissions(rolePermissions) {
          if (!this.selectedDefaultMode) return rolePermissions
          for(let rolePermission of rolePermissions) {
            rolePermission.templatePermissions = this.modeOptions[this.selectedDefaultMode]
          }
          return rolePermissions
        },
        loadPermissionsTable(response){
          //create table data:
          let d = {}
          let permissionData = []
          this.rolePermissions = response.data.payload.rolePermissions
          // console.info(`this.rolePermissions:`, this.rolePermissions)

          for(let rolePermission of response.data.payload.rolePermissions) {
            d[rolePermission.role_name] = rolePermission
          }
          // console.info(`d:`, d)

          for(let key in response.data.payload.rolePermissions[0].curPermissions) {
            let row = {}
            row['permission'] = key
            // row['Actions'] = 'test'
            for(let role of response.data.payload.rolePermissions) {
              if (this.showAllRoles !== true && role.schedulePhaseAccess !== true) continue

              row.companyId = role.companyId
              row.schedule_id = role.schedule_id
              row.phaseOrdernr = role.phaseOrdernr
              row.schedulePhaseAccess = role.schedulePhaseAccess
              row[`${role.role_name}`] = d[role.role_name].curPermissions[key] ? 'X' : '-'

              // add questions when permission does not match template
              if (d[role.role_name].schedulePhaseAccess === true && d[role.role_name].curPermissions[key] !== d[role.role_name].templatePermissions[key]) {
                row[`${role.role_name}`] = row[`${role.role_name}`] + ' ?????'
                // row[`${role.role_name}`] = row[`${role.role_name}`]
              }

              row[`${role.role_name}_role`] = role
            }

            permissionData.push(row)
          }


          let permissionColumnData = []
          permissionColumnData.push({ field: 'permission', label: 'permission', width: 40, searchable: true, centered: false, sortable: true })
          // permissionColumnData.push({ field: 'Actions', label: 'Actions', width: 40, searchable: true, centered: false, sortable: true })

          for(let role of response.data.payload.rolePermissions) {
            if (this.showAllRoles !== true && role.schedulePhaseAccess !== true) continue

            permissionColumnData.push({ field: `${role.role_name}`, label: `${role.role_name} (${role.totalUsers})`, width: 40, searchable: true, centered: true, sortable: true })
          }

          this.permissionColumnData = permissionColumnData
          // console.info(`this.permissionColumnData:`, this.permissionColumnData)

          this.permissionData = permissionData
          // console.info(`permissionData:`, permissionData)

          let anyOneCanCancel = false
          for(let role of response.data.payload.rolePermissions) {
            if (this.showAllRoles !== true && role.schedulePhaseAccess !== true) continue

            if (role.curPermissions['cancel_assigned_own_response'] === true) {
              // console.info(`role ${role.role_id} - ${role.role_name} can cancel!`)
              anyOneCanCancel = true
            }
          }
          this.anyOneCanCancel = anyOneCanCancel

          let anyOneCanAssign = false
          for(let role of response.data.payload.rolePermissions) {
            if (this.showAllRoles !== true && role.schedulePhaseAccess !== true) continue

            if (role.curPermissions['assign_own_response'] === true) {
              // console.info(`role ${role.role_id} - ${role.role_name} can assign!`)
              anyOneCanAssign = true
            }
          }
          this.anyOneCanAssign = anyOneCanAssign


          let cancelUnlimited = false
          for(let role of response.data.payload.rolePermissions) {
            if (this.showAllRoles !== true && role.schedulePhaseAccess !== true) continue

            if (role.curPermissions['cancel_unlimited_responses'] === true) {
              console.info(`role ${role.role_id} - ${role.role_name} can cancel unlimted!!`)
              cancelUnlimited = true
            }
          }

          this.cancelUnlimited = cancelUnlimited

          this.lastUpdated = new Date().getTime()
        },

        getBackgroundClass(){
          let warnings = []
          if (this.cancelUnlimited) warnings.push(`cancel unlimited active`)
          if (this.anyOneCanCancel) warnings.push(`cancel found`)
          if (this.anyOneCanAssign) warnings.push(`assign found`)
          if (this.warnings.join(`,`) !== warnings.join(`,`)) { //prevent reder issues, only update when changed!
            this.warnings = warnings
            // console.info(`this.warnings:`, this.warnings)
          }


          if (this.cancelUnlimited) return "cancelUnlimited"
          if (this.anyOneCanCancel) return "anyoneCanCancle"
          if (this.anyOneCanAssign) return "anyOneCanAssign"

          return ""
        },

        selectPeriod(period){
          this.selectedPeriod = period
          this.showSchedule(null)
        },

        showSchedule(newSchedule){
          // console.info(`showSchedule:`, schedule)
          let selectedSchedule = null
          for(let schedule of this.scheduleData) {
            if (newSchedule && schedule.id === newSchedule.id) {
              schedule.btnActive = true
              selectedSchedule = schedule
            } else {
              schedule.btnActive = false
            }
          }


          // let phases = [
          //   {
          //     name: `Phase 1`,
          //     phaseId: 10,
          //     btnActive: selectedSchedule ? selectedSchedule.currentPhaseOrderNumber === 10 : false,
          //     isCurrent: selectedSchedule ? selectedSchedule.currentPhaseOrderNumber === 10 : false
          //   }, {
          //     name: `Phase 2`,
          //     phaseId: 20,
          //     btnActive: selectedSchedule ? selectedSchedule.currentPhaseOrderNumber === 20 : false,
          //     isCurrent: selectedSchedule ? selectedSchedule.currentPhaseOrderNumber === 20 : false
          //   }, {
          //     name: `Phase 3`,
          //     phaseId: 30,
          //     btnActive: selectedSchedule ? selectedSchedule.currentPhaseOrderNumber === 30 : false,
          //     isCurrent: selectedSchedule ? selectedSchedule.currentPhaseOrderNumber === 30 : false
          //   }
          // ]
          let phases = []
          const curDate = new Date()
          if (selectedSchedule) {
            for(let phase of selectedSchedule.phases) {
              let item = {
                name: phase.name ? `${phase.order_number} - ${phase.name}` : `phase_${phase.order_number}`,
                phaseId: phase.order_number,
                btnActive: selectedSchedule.currentPhaseOrderNumber === phase.order_number,
                isCurrent: selectedSchedule.currentPhaseOrderNumber === phase.order_number,
                cancelPercent: phase.max_shifts_cancelation ? phase.max_shifts_cancelation : 0,
                initiate_at: phase.initiate_at ? new Date(phase.initiate_at) : 'no init date!',
                initiateAtStr: phase.initiate_at ? this.DDMMYYYY_HHii(new Date(phase.initiate_at)) : 'no init date!',
                hasInitiateAt: phase.initiate_at !== null ? true : false,
                daysTillInit: 0,
                style: 'initRed'
              }

              if (item.hasInitiateAt) {
                item.daysTillInit = Math.round(10 * Math.max(0, (item.initiate_at.getTime() - curDate.getTime()) / 1000 / 24 / 3600))/10
                if (item.daysTillInit > 0) {
                  item.style = 'initGreen'
                } else {
                  item.style = 'initGrey'
                }
              }

              phases.push(item)
            }
          }



          this.schedulePhases = phases
          this.selectedSchedule = selectedSchedule;
          // console.info(`this.schedulePhases:`, this.schedulePhases)


          this.lastUpdated = new Date().getTime()
          // console.info(`scheduleData:`, this.scheduleData)

          // if (!this.selectedPhase) {
          if (this.selectedSchedule) {
            this.showPhase(phases.find((phase) => {return  phase.isCurrent === true }))
          }
          // } else {
            // this.showPhase(this.selectedPhase)
          // }
        },

        async togglePermission(props, field){
          if (!this.selectedSchedule) return;

          // console.info(`togglePermission:`, props.permission)
          // console.info(`togglePermission field:`, field)
          let findRole = this.rolePermissions.find(role => role.role_name === field)
          // console.info(`findRole:`, findRole)
          // console.info(`findRole curPermission:`, findRole.curPermissions[props.permission])
          // console.info(`togglePermission field:`, this.permissionColumnData.find(row => row.permission === props.permission))

          let newState = findRole.curPermissions[props.permission] === true ? '0' : '1'

          this.ShowPageLoading(500)
          let response = await axios_api.post(`${API_ROOT}/schedule/company/${this.companyId}/schedulePermissions/Change`, {
            scheduleId: this.selectedSchedule.id,
            phaseId: this.selectedPhase.phaseId,
            roleId: findRole.role_id,
            permission: props.permission,
            newState: newState
          })
          // return

          if (response.data.result !== 1) {
            this.$buefy.dialog.alert({ title: 'Error', message: 'unable to set phase permission', type: 'is-danger' })
            this.HidePageLoading()
            return;
          }
          // console.info(`response:`, response)
          this.HidePageLoading()

          // this.getSchedulePermissionData()
          this.showPhase(this.selectedPhase)
        },

        saveColumn(props){
          console.info(`saveColumn:`, props)
        },

        setDefaultMode(defaultMode){
          let selectedMode = null
          if (this.selectedDefaultMode !== defaultMode) {
            selectedMode = defaultMode
          }
          this.selectedDefaultMode = selectedMode
          if (this.selectedPhase) {
            this.showPhase(this.selectedPhase)
          }
        },

        async setPhase(phase){
          // this.setPhaseConfirm(phase)
          // return
          this.$buefy.dialog.confirm({
            title: 'are you sure?',
            message: `Confirm switching phase to: '${phase.name}'`,
            cancelText: 'No',
            confirmText: 'Yes',
            type: 'is-success',
            onConfirm: () => this.setPhaseConfirm(phase)
          })
        },
        async addToScheduleList(phase){
          console.info(`selectedPeriod:`, this.selectedPeriod)          
          console.info(`selectedSchedule:`, this.selectedSchedule)          
          console.info(`addToScheduleList:`, phase)
          
          let list = this.toScheduleList.filter(s => s.scheduleId !== this.selectedSchedule.id)
          list.push({
            scheduleId: this.selectedSchedule.id,
            schedule_name: this.selectedSchedule.name,
            phaseId: phase.phaseId,
            phaseName: phase.name
          })     
          list = list.sort((a,b) => { return a.scheduleId - b.scheduleId})     
          this.toScheduleList = list
          console.info(`toScheduleList:`, this.toScheduleList)
        },
        removeToScheduleItem (toScheduleItem) {
          this.toScheduleList = this.toScheduleList.filter(s => s.scheduleId !== toScheduleItem.scheduleId)
        },
        StartSchedule(){
          
        },
        clearDateTime () {
          this.selected = null
        },
        async setPhaseConfirm(phase){
          console.info(`set phase:`, phase)
          this.ShowPageLoading(500)
          let response = await axios_api.post(`${API_ROOT}/schedule/company/${this.companyId}/schedulePermissions/setPhase`, {
            scheduleId: this.selectedSchedule.id,
            phaseId: this.selectedPhase.phaseId            
          })
          if (response.data.result !== 1) {
            this.$buefy.dialog.alert({ title: 'Error', message: 'unable to set phase permission', type: 'is-danger' })
            this.HidePageLoading()
            return;
          }
          // console.info(`response:`, response)
          this.HidePageLoading()
        },
        async savePhase(phase){
          // console.info(`save phase:`, phase)

          this.ShowPageLoading(500)
          let response = await axios_api.post(`${API_ROOT}/schedule/company/${this.companyId}/schedulePermissions/Save`, {
            scheduleId: this.selectedSchedule.id,
            phaseId: phase.phaseId
          })
          // return

          if (response.data.result !== 1) {
            this.$buefy.dialog.alert({ title: 'Error', message: 'unable to set phase permission', type: 'is-danger' })
            this.HidePageLoading()
            return;
          }
          // console.info(`response:`, response)
          this.HidePageLoading()

          // this.getSchedulePermissionData()
          this.showPhase(this.selectedPhase)
        }


      }
    }
</script>

<style>
  .highlight{
    color: #0000cc;
    text-decoration: underline;
  }

  /* .isAvtive{
    color: green
  } */
  .notActive{
    color: red
  }

  .smallLetters{
    font-size: 12px;
    margin-bottom: 15px;;
  }

  .menuUrls a:hover{
    text-decoration: underline;
  }

  .cellClickAction:hover{
    background-color:green;
    cursor: pointer
  }
  
  .table.is-narrow td, .table.is-narrow th{
    padding: 0;
    margin:3px;  
  }

  .anyoneCanCancle{
    background-color: rgba(255, 28, 28, 0.2);
    height: 2000px;
  }
  .anyOneCanAssign{
    background-color: rgba(190, 247, 184, 0.918);
    height: 2000px;
  }
  .cancelUnlimited{
    background-color: rgba(255, 153, 0, 0.918);
    height: 2000px;
  }

  .ScheduleInactive{
    color: red;
  }

  .initRed{
    font-size: 12px;
    color: red;
  }
  .initGrey{
    font-size: 12px;
    color: grey;
  }
  .initGreen{
    font-size: 12px;
    color: green;
    font-weight: bold;
  }

  .button.is-success .initGreen{
    color: white;
  }

  .tableCellHighlight{
    background: lightblue !important;
    /* color: white !important; */
  }

  .column.mypadding{
    padding:3px
  }

  span.savePhase:hover{
    text-decoration: underline;
    cursor: pointer;
  }

  span.setPhase{
    padding-left:15px;
  }
  
  span.setPhase:hover{
    text-decoration: underline;
    cursor: pointer;
  }
  
  span.addToScheduleList{
    padding-left:15px;
  }
  
  span.addToScheduleList:hover{
    text-decoration: underline;
    cursor: pointer;
  }
  
  span.removeToScheduleItem{
    padding-left:15px;
  }
  
  span.removeToScheduleItem:hover{
    text-decoration: underline;
    cursor: pointer;
  }

  .defaultBoxWrap{
    position: relative;
  }

  .defaultModesWrap{
    float: right;
    top: 3px;
    right: 0;
    position: absolute;
    z-index: 999;
  }


  .defaultMode{
    float:right;
    /* width: 50px; */
    /* height: 30px; */
    padding: 5px;
    margin-right: 5px;
    color: black;
    border: 1px solid #AEAEAE;
  }
  .defaultMode:hover{
    cursor: pointer;
    border: 1px solid #0000cc;
  }

  .defaultMode.selected{
    background-color: green;
    border: 1px solid white;
    color: #FFF;
  }


</style>